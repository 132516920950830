import React from 'react';
import {Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { UserList, UserEdit, UserCreate } from './users/users'
import { AdminList, AdminEdit, AdminCreate } from './admins'
import authProvider from './authProvider'
import { OutletList, OutletEdit, OutletCreate } from './outlets/outlets'
import UserIcon from '@material-ui/icons/People';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {ReportList1} from './reports/report1'
import {ReportList2} from './reports/report2'
import {ReportList3} from './reports/report3'
import {ReportList4} from './reports/report4'
import {ReportList5} from './reports/report5'

import {ReportListManager1} from './reports_manager/reportManager1'
import {ReportListManager2} from './reports_manager/reportManager2'
import {ReportListManager3} from './reports_manager/reportManager3'
import {ReportListManager4} from './reports_manager/reportManager4'
import {ReportListManager5} from './reports_manager/reportManager5'

import {HOST} from './security/config'
import { ManagerList, ManagerEdit, ManagerCreate } from './managers'
import fetchJson from './util/fetchJson' 
import {Feedback} from './feedback';
import ReportIcon from '@material-ui/icons/Description'
import CommentIcon from '@material-ui/icons/Comment'
import MyLayout from './MyLayout';
import { ProfileEdit } from "./profile";
import { Route } from 'react-router-dom';
//import dataProvider from './dataProvider';

const httpClient = (url, options = {}) => {
  /*if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const  token  = localStorage.getItem("token");
  console.log("send auth token: ", token)
  options.credentials = 'include';
  options.headers.set('Authorization', `${token}`);*/
  const  token  = localStorage.getItem("token");
  options.user = {
    authenticated: true,
    token: token
};
  return fetchJson(url, options);
};

/*window.onbeforeunload = function(e){
  const request = new Request(HOST+'/logout', {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' , 'Authorization' : localStorage.getItem("token")}),
});
fetch(request)
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('user');
        localStorage.removeItem('userview');
}*/

const dataProvider = jsonServerProvider(HOST+'/admin', httpClient);

const App = () => (

  <Admin authProvider={authProvider} dataProvider={dataProvider}  customRoutes={[<Route key="my-profile" path="/my-profile" component={ProfileEdit} />]} appLayout={MyLayout}>

    {permissions => [
      permissions === 'super_admin'
        ? <Resource name='admins' options={{ label: 'Администраторы' }} icon={VerifiedUserIcon} list={AdminList} edit={AdminEdit} create={AdminCreate} />
        : null,
      permissions === 'super_admin'
        ? <Resource name='users' options={{ label: 'Пользователи' }} icon={UserIcon} list={UserList} edit={UserEdit} create={UserCreate} />
        : null,
      permissions === 'super_admin'
        ? <Resource name='outlets' options={{ label: 'Точки продаж' }} icon={ShoppingCartIcon} list={OutletList} edit={OutletEdit} create={OutletCreate} />
        : null,
      permissions === 'super_admin'
        ? <Resource name='managers' options={{ label: 'Менеджеры' }} icon={UserIcon} list={ManagerList} edit={ManagerEdit} create={ManagerCreate} />
        : null,  

      permissions === 'admin'
        ? <Resource name='users' options={{ label: 'Пользователи' }} icon={UserIcon} list={UserList} edit={UserEdit} create={UserCreate} />
        : null,
      permissions === 'admin'
        ? <Resource name='outlets' options={{ label: 'Точки продаж' }} icon={ShoppingCartIcon} list={OutletList} edit={OutletEdit} create={OutletCreate} />
        : null,
      permissions === 'admin'
        ? <Resource name='managers' options={{ label: 'Менеджеры' }} icon={UserIcon} list={ManagerList} edit={ManagerEdit} create={ManagerCreate} />
        : null,  

      permissions === 'user'
        ? <Resource name='report1' options={{ label: 'Отчет распрстранителя' }}  icon={ReportIcon} list={ReportList1} show={ReportList1}/>
        : null,
      permissions === 'user'
        ? <Resource name='report2' options={{ label: 'Акт сверки' }} icon={ReportIcon} list={ReportList2} />
        : null,
      permissions === 'user'
        ? <Resource name='report3' options={{ label: 'Акт выполненных работ' }} icon={ReportIcon} list={ReportList3} />
        : null,
      permissions === 'user'
        ? <Resource name='report4' options={{ label: 'Счет на оплату' }} icon={ReportIcon} list={ReportList4} />
        : null,
      permissions === 'user'
        ? <Resource name='report5' options={{ label: 'Акт описи активов' }} icon={ReportIcon} list={ReportList5} />
        : null,
      permissions === 'user'
        ? <Resource name='feedback' options={{ label: 'Обратная связь' }}  icon={CommentIcon} list={Feedback} />
        : null,
        permissions === 'user'
        ? <Resource name="profile"/>
        : null,

      permissions === 'manager'
        ? <Resource name='reportManager1' options={{ label: 'Отчет распрстранителя' }} icon={ReportIcon} list={ReportListManager1} show={ReportListManager1}/>
        : null,
      permissions === 'manager'
        ? <Resource name='reportManager2' options={{ label: 'Акт сверки' }} icon={ReportIcon} list={ReportListManager2} />
        : null,
      permissions === 'manager'
        ? <Resource name='reportManager3' options={{ label: 'Акт выполненных работ' }} icon={ReportIcon} list={ReportListManager3} />
        : null,
      permissions === 'manager'
        ? <Resource name='reportManager4' options={{ label: 'Счет на оплату' }} icon={ReportIcon} list={ReportListManager4} />
        : null,
      permissions === 'manager'
        ? <Resource name='reportManager5' options={{ label: 'Акт описи активов' }} icon={ReportIcon} list={ReportListManager5} />
        : null,
      permissions === 'manager'
        ? <Resource name='feedback' options={{ label: 'Обратная связь' }}  icon={CommentIcon} list={Feedback} />
        : null,
        permissions === 'manager'
        ? <Resource name="profile"/>
        : null,
  
    ]}
    
  </Admin>

);



/*const App = () => (   
   <Admin authProvider={authProvider} dataProvider={dataProvider} >
        <Resource name='users' options={{ label: 'Пользователи' }} icon={UserIcon} list={UserList} edit={UserEdit} create={UserCreate} changepass={UserChangePass}/>
        <Resource name='outlets' options={{ label: 'Точки продаж' }} icon={ShoppingCartIcon} list={OutletList} edit={OutletEdit} create={OutletCreate}/>
        <Resource name='admins' options={{ label: 'Администраторы' }} icon={VerifiedUserIcon} list={AdminList} edit={AdminEdit} create={AdminCreate}/>
        <Resource name='report1' options={{ label: 'Отчет распрстранителя' }} list={ReportList1} />
        <Resource name='report2' options={{ label: 'Акт сверки' }} list={ReportList2} />
        <Resource name='report3' options={{ label: 'Акт выполненных работ' }} list={ReportList3} />
        <Resource name='report4' options={{ label: 'Счет на оплату' }} list={ReportList4} />
     </Admin>
    
   );*/


export default App;
