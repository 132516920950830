import React from "react";
import { SimpleForm, List, TextField} from 'react-admin';
import Button from "@material-ui/core/Button";
//import Select from "@material-ui/core/Select";
import Select from 'react-select';
//import decodeJwt from 'jwt-decode';
import moment from 'moment';
import { useState } from 'react';
import axios from 'axios' 
import {REPORT} from '../security/config';
import {
    makeStyles
  } from "@material-ui/core/styles";
  import Menu from '@material-ui/core/Menu';
  import MenuItem from '@material-ui/core/MenuItem';
  import { withStyles } from '@material-ui/core/styles';
  import { saveAs } from 'file-saver';
  import jwt_decode from 'jwt-decode';
  import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClockLoader";
  
/*form style code--------------------------------------*/
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));


  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  /*---------------------------------------------------- */ 


let Rep4_date_from = new Date();
let Rep4_date_to = new Date();


const ref = React.createRef();

  var decodedToken;
  var UserName;
  var UserView;
  
  try {
    decodedToken = jwt_decode(localStorage.getItem("token"))
     UserName = decodedToken.username;
     UserView = decodedToken.userview;
     console.log('r4 u '+ UserName);
     console.log('r4 v '+ UserView);
  } catch {
    console.log('Error decode token');
  }

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;
  
const AsideRep4 = () => {
    const [Report, setReport] = useState('Выберите ритейлера и нажмите кнопку Запросить');
    const [Options, setOptions] = useState([]);
    const [Value, setValue] = useState('');
    const [ButtonState, setButtonState] = useState(false);
    const [Export, setExport] = useState('');
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#0c90e8");

      if (Options.length === 0) {
       let qry = axios
   
       qry.get(REPORT + '/ritalers', {
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization': localStorage.getItem("token")
         }
       }).then((response) => {
         let Options = response.data.map(ritaler => ({ value: ritaler.value, label: ritaler.label }));
         console.log('data', response.data)
         console.log('options', Options)
         setOptions(Options)
       }).catch((error) => {
         console.error('getOptions', error)
         return new Error(error)
       })}
       
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
     setAnchorEl(null);
    };
    return(
    <div style={{ width: 1500, margin: '1em' }}>
        <div className={classes.root}>
        <div style={{ width: '200px' }}>
          <Select source="ritaler"
            multi={false}
            options={Options}
            value={Options.find(obj => obj.value === Value)}
            onChange={e => setValue({ Options: e.value })} />
        </div>
        <Button color="primary" variant="contained" source="reportQuery" disabled={ButtonState} onClick={() =>  {
           setButtonState(true)
           setReport('Идет загрузка, подождите...')
           setLoading(!loading)

         let qry = axios

         var string = JSON.stringify(Value);
         let obj = JSON.parse(string);
         const data = {
             retailer : obj.Options,
             date_from : moment(Rep4_date_from).format('DD.MM.YYYY'),
             date_to: moment(Rep4_date_to).format('DD.MM.YYYY'),
             section: '4'
         }
     
          qry.post(REPORT+'/admin/data', data, {
             headers: {
                 'Content-Type': 'application/json',
                 'Authorization' : localStorage.getItem("token")
             }
         }).then((response) => {
             setLoading(loading)
            setButtonState(false)
            console.log('data', response.data)
            setReport(response.data)
            setExport(response.data)
         }).catch((error) => {
          setLoading(loading)
          setButtonState(false)
             return new Error(error)
         });   
        }
        }>Запросить </Button>
        
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} label="Экспорт в pdf" color="secondary" variant="contained" source="reportExport">экспорт</Button>

        <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
         <MenuItem onClick={() => {
           setReport('Идет загрузка, подождите...')
           setLoading(!loading)

                       let qry = axios
                       console.log('HTML to send', ref.current.innerHTML)
                     qry.post(REPORT+'/admin/report4PDF', ref.current.innerHTML, {
                      responseType: 'arraybuffer',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization' : localStorage.getItem("token")
                        }
                    }).then((response) => {
                      setLoading(loading)
              setReport(Export)
                        let blob = new Blob([response.data], { type: "application/pdf"});
                        saveAs(blob, "Report4.pdf");
                    }).catch((error) => {
                      setLoading(loading)
              setReport(Export)
                        return new Error(error)
                    });   
                }}>PDF</MenuItem>
        <MenuItem onClick={()=>{
setReport('Идет загрузка, подождите...')
setLoading(!loading)

    let qry = axios
    var string = JSON.stringify(Value);
    let obj = JSON.parse(string);
    const data = {
        retailer : obj.Options,
        date_from : moment(Rep4_date_from).format('DD.MM.YYYY'),
        date_to: moment(Rep4_date_to).format('DD.MM.YYYY'),
        section: '1'
    }

     qry.post(REPORT+'/admin/report4XLS', data, {
      responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : localStorage.getItem("token")
        }
    }).then((response) => {
      setLoading(loading)
      setReport(Export)
        //console.log('data',response.data)
        //setReport(response.data)
        //var blob = new Blob([s2ab(atob(response.data))], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'});
        let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
        saveAs(blob, "Report4.xlsx");
    }).catch((error) => {
      setLoading(loading)
      setReport(Export)
        return new Error(error)
    });   
        }}>EXCEL</MenuItem>
      </StyledMenu>

        </div>
        <div style={{width: 1600, height: 800}} ref={ref}>
          <div dangerouslySetInnerHTML={{ __html: Report }} />
           <div style={{ width: 1000, height: 1000 }}>
             <ClipLoader color={color} loading={loading} css={override} size={100} />
           </div>
        </div>
    </div>
);
};

export const ReportListManager4 = props => (
    <List title="Счет на оплату" aside={<AsideRep4 />} {...props} bulkActionButtons={false} actions={false} pagination={false}>
        <SimpleForm toolbar={false}>
        <p>Наименование: {UserView} </p>
            <TextField source="title" label="Указывать период не требуется" />
        </SimpleForm>
        </List>
  );
  
  /* <DateInput label="Дата с" source="rep4_pub_at" defaultValue={Rep4_date_from} onChange={handleChangeDateFromRep4}/>
            <DateInput label="Дата по" source="rep4_pub_to" defaultValue={Rep4_date_from} onChange={handleChangeDateToRep4}/>*/ 
  //<p>Наименование: {UserView} </p>
  // <p>Ритейлер ID: {UserName} </p>