import React from 'react';
import { List, Datagrid, TextField, SimpleForm, TextInput, Edit, Create, BooleanInput, BooleanField } from 'react-admin';
//import * as React from 'react';
import { Button, CreateButton, ExportButton, TopToolbar } from 'react-admin';
import IconImport from '@material-ui/icons/ImportExportOutlined';
import axios from 'axios'; 
import {REPORT} from '../security/config';



const ListActions = (props) => {
  const { className, basePath } = props;


  return (


    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <Button
        onClick={() => {
          var input = document.createElement('input');
          input.type = 'file';

          input.onchange = e => {

            // getting a hold of the file reference
            var file = e.target.files[0];

            // setting up the reader
            var reader = new FileReader();
            reader.readAsText(file, 'UTF-8');

            // here we tell the reader what to do when it's done reading...
            reader.onload = readerEvent => {
              var content = readerEvent.target.result; // this is the content!
              let qry = axios;
              console.log(content);  
                     qry.post(REPORT+'/admin/createuserList', content, {
                      //responseType: 'arraybuffer',
                        headers: {
                            'Content-Type': 'application/csv',
                            'Authorization' : localStorage.getItem("token")
                        }
                    }).then((response) => {
                      console.log('response',response); 
                      if (response.status === 200) {
                        alert('Импорт выполнен успешно! Обновите страницу.');
                      } else {
                        alert('Импорт выполнен с ошибками!');
                      }
                    }).catch((error) => {
                        return new Error(error)
                    });   
            }

          }

          input.click();
        }}
        label="Import"
      >
        <IconImport />
      </Button>

      <ExportButton />
    </TopToolbar>
  );
};


export const UserList = props => (
  <List title="Пользователи" actions={<ListActions />} {...props}>
    <Datagrid rowClick='edit'>
      <TextField source='id' label="№" />
      <TextField source='name' label="Логин" />
      <TextField source='name_view' label="Наименование" />
      <BooleanField source='active' label="Активный" />
      <BooleanField source='report1' label="Отчет распространителя" />
      <BooleanField source='report2' label="Акт сверки" />
      <BooleanField source='report3' label="Акт выполненных работ" />
      <BooleanField source='report4' label="Счет на оплату" />
    </Datagrid>
  </List>
);

export const UserEdit = props => (
  <Edit title="Пользователи" {...props}>
    <SimpleForm>
      <TextField source='id' label="№" />
      <TextInput source='name' label="Логин" />
      <TextInput source='name_view' label="Наименование" />
      <BooleanInput source='active' label="Активный" />
      <BooleanInput source='report1' label="Отчет распространителя" />
      <BooleanInput source='report2' label="Акт сверки" />
      <BooleanInput source='report3' label="Акт выполненных работ" />
      <BooleanInput source='report4' label="Счет на оплату" />
      <legend class="groupbox-border">Сменить пароль</legend>

      <TextInput source='new_password' label='Новый пароль' type='password' />
    </SimpleForm>
  </Edit>
);

export const UserCreate = props => (
  <Create title="Пользователи" {...props}>
    <SimpleForm>
      <TextInput source='name' label="Логин" />
      <TextInput source='name_view' label="Наименование" />
      <TextInput source='password' label='Пароль' type='password' />
      <BooleanInput source='active' label="Активный" />
      <BooleanInput source='report1' label="Отчет распространителя" />
      <BooleanInput source='report2' label="Акт сверки" />
      <BooleanInput source='report3' label="Акт выполненных работ" />
      <BooleanInput source='report4' label="Счет на оплату" />
    </SimpleForm>
  </Create>
);



/*const PostEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
      <ShowButton basePath={basePath} record={data} />
      <Button color="primary" onClick={customAction}>Custom Action</Button>
  </TopToolbar>
);

export const PostEdit = (props) => (
  <Edit actions={<PostEditActions />} {...props}>
      ...
  </Edit>
);*/