import React from "react";
import { SimpleForm, List, TextField } from 'react-admin';
import Button from "@material-ui/core/Button";
import { useState } from 'react';
import axios from 'axios'
import { REPORT } from '../security/config';
import {
    makeStyles
} from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';
import jwt_decode from 'jwt-decode';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClockLoader";

/**form style code--------------------------------------*/
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

/*---------------------------------------------------- */



var decodedToken;
var UserName;
var UserView;

try {
    decodedToken = jwt_decode(localStorage.getItem("token"))
    UserName = decodedToken.username;
    UserView = decodedToken.userview;
    console.log('r5 u ' + UserName);
    console.log('r5 v ' + UserView);
} catch {
    console.log('Error decode token');
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

const AsideRep1 = () => {
    const [Report, setReport] = useState('Нажмите кнопку Запросить');
    const [ButtonState, setButtonState] = useState(false);

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#0c90e8");

    return (
        <div style={{ width: 1500, margin: '1em' }}>
            <div className={classes.root}>
                <Button color="primary" variant="contained" source="reportQuery" disabled={ButtonState} onClick={() => {
                    const decodedToken = jwt_decode(localStorage.getItem("token"));
                    setButtonState(true)
                    setReport('Идет загрузка, подождите...')
                    setLoading(!loading)

                    let qry = axios

                    const data = {
                        retailer: decodedToken.username,
                        section: '1'
                    }

                    qry.post(REPORT + '/admin/ReportInvertory', data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem("token")
                        }
                    }).then((response) => {
                        setLoading(loading)
                        setButtonState(false)
                        console.log('data', response.data)
                        setReport(response.data)
                    }).catch((error) => {
                        setLoading(loading)
                        setButtonState(false)
                        return new Error(error)
                    });
                }}>Запросить </Button>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} color="secondary" variant="contained" source="reportExport">экспорт</Button>

                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => {
                        setReport('Идет загрузка, подождите...')
                        setLoading(!loading)

                        let qry = axios
                        let bdy = document.getElementById("source_body").innerHTML
                        console.log('Bdy', bdy)
                        //setExport(document.getElementById("source_body").innerHTML)
                        //console.log('Export body', Export)
                        qry.post(REPORT + '/admin/report5PDF', bdy, {
                            responseType: 'arraybuffer',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': localStorage.getItem("token")
                            }
                        }).then((response) => {
                            setLoading(loading)
                            setReport(bdy)
                            let blob = new Blob([response.data], { type: "application/pdf" });
                            saveAs(blob, "Report5.pdf");
                        }).catch((error) => {
                            setLoading(loading)
                            setReport(bdy)
                            return new Error(error)
                        });
                    }}>PDF</MenuItem>

                    <MenuItem onClick={() => {
                        setReport('Идет загрузка, подождите...')
                        setLoading(!loading)
                        let qry = axios
                        let bdy = document.getElementById("source_body").innerHTML
                        console.log('Bdy', bdy)
                        //setExport(ref.current.innerHTML)
                        qry.post(REPORT + '/admin/report5XLS', bdy, {
                            responseType: 'arraybuffer',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': localStorage.getItem("token")
                            }
                        }).then((response) => {
                            setLoading(loading)
                            setReport(bdy)
                            let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
                            saveAs(blob, "Report5.xlsx");
                        }).catch((error) => {
                            setLoading(loading)
                            setReport(bdy)
                            return new Error(error)
                        });
                    }}>EXCEL</MenuItem>
                </StyledMenu>
            </div>
            <div style={{ width: 1000, height: 2500 }} id="source_body">
                <div dangerouslySetInnerHTML={{ __html: Report }} />
                <div style={{ width: 1000, height: 1000 }}>
                    <ClipLoader color={color} loading={loading} css={override} size={100} />
                </div>
            </div>
        </div>
    );
};

/*
{
            var blob = new Blob([Report],
                { type: "application/vnd.ms-excel" });
            saveAs(blob, "Report1.xlt");
        }
*/
export const ReportList5 = props => (
    <List title="Акт описи активов" aside={<AsideRep1 />} {...props} syncWithLocation={false} bulkActionButtons={false} actions={false} pagination={false}>
        <SimpleForm toolbar={false} >
            <p>Наименование: {UserView} </p>
            <p>Ритейлер ID: {UserName} </p>
            <TextField source="title" label="Указывать период не требуется" />
        </SimpleForm>
    </List>
);

//<p>Наименование: {UserView} </p>
//<p>Ритейлер ID: {UserName} </p>

