import React from "react";
import {TextInput, SimpleForm, required } from "react-admin";
import axios from 'axios';
import { REPORT } from './security/config';



export const ProfileEdit = ({ staticContext, ...props }) => {
  const handleSave = (values) => {
    console.log({ data: values });
    let qry = axios
   
    const data = { values }

    qry.post(REPORT + '/admin/changeuserpass', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }
      
    }
    ).then((response) => {
      console.log('data', response.data)
       var string_resp = JSON.stringify(response.data);
       let obj_resp = JSON.parse(string_resp);
    
     if (response.status < 200 || response.status >= 300) {        
        alert('Не удалось отправить запрос, повторите попытку позднее. Код ошибки :'+response.status);
      } else {
        alert(obj_resp.status);
      }
    }).catch((error) => {
      return new Error(error)
    });
  };


  return ( 
    <SimpleForm save={handleSave}>
      <legend class="groupbox-border">Сменить пароль</legend>
      <TextInput source="old" label="Текущий пароль" validate={required()} type='password'/>
      <TextInput source="new" label="Новый пароль" validate={required()} type='password'/>
      <TextInput source="confirm" label="Подтверждение пароля" validate={required()} type='password'/>
    </SimpleForm>
  );
};