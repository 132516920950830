import React from 'react';
import { List, Datagrid, TextField, SimpleForm, TextInput, Edit, Create, BooleanInput, BooleanField } from 'react-admin';

export const AdminList = props => (
  <List title="Администраторы" {...props}>
   <Datagrid rowClick='edit'>
      <TextField source='id' label="№"/>
      <TextField source='name' label="Логин"/>
      <BooleanField source='active'label="Активный"/>
    </Datagrid>
  </List>
);

export const AdminEdit = props => (
  <Edit title="Администраторы" {...props}>
    <SimpleForm>
      <TextField source='id' label="№"/>
      <TextInput source='name' label="Логин"/>
      <BooleanInput source='active' label="Активный"/>
      <legend class="groupbox-border">Сменить пароль</legend>

      <TextInput source='new_password' label='Новый пароль' type='password'/>
    </SimpleForm>
  </Edit>
);

export const AdminCreate = props => (
  <Create title="Администраторы" {...props}>
    <SimpleForm>
      <TextInput source='name' label="Логин"/>
      <TextInput source='password' label='Пароль' type='password'/>
      <BooleanInput source='active' label="Активный"/>
    </SimpleForm>
  </Create>
);
