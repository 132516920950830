
/*Local debug params */
/*export const HOST = 'http://192.168.1.107:5000';
export const REPORT = 'http://192.168.1.107:5000';*/

/*product server params*/ 
export const HOST = 'http://185.253.8.122:5005';
export const REPORT = 'http://185.253.8.122:5005';

/*test server params*/ 
/*export const HOST = 'http://172.20.0.33:5000';
export const REPORT = 'http://172.20.0.33:5000';*/