import React from "react";
import { SimpleForm, DateInput, List, TextField } from 'react-admin';
import Button from "@material-ui/core/Button";
//import Select from "@material-ui/core/Select";
import Select from 'react-select';
//import decodeJwt from 'jwt-decode';
import moment from 'moment';
import { useState } from 'react';
import axios from 'axios'
import { REPORT } from '../security/config';
import {
  makeStyles
} from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';
import jwt_decode from 'jwt-decode';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClockLoader";


// const decodeJwt = require('jwt-decode');
/**form style code--------------------------------------*/
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

/*---------------------------------------------------- */


let Rep1_date_from = new Date();
let Rep1_date_to = new Date();


const HandleChangeDateFromRep1 = event => {
  console.log(Rep1_date_from);
  Rep1_date_from = event.target.value;
  console.log('datefrom value', moment(Rep1_date_from).format('DD.MM.YYYY'));
};

const handleChangeDateToRep1 = event => {
  console.log(event.target.value);
  Rep1_date_to = event.target.value;
  console.log('datefrom value', moment(Rep1_date_to).format('DD.MM.YYYY'));
};


const ref = React.createRef();


var decodedToken;
var UserName;
var UserView;

try {
  decodedToken = jwt_decode(localStorage.getItem("token"))
  UserName = decodedToken.username;
  UserView = decodedToken.userview;
  console.log('r1 u ' + UserName);
  console.log('r1 v ' + UserView);
} catch {
  console.log('Error decode token');
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

const AsideRep1 = () => {
  const [Report, setReport] = useState('Укажите период отчета, выберите ритейлера и нажмите кнопку Запросить');
  const [Options, setOptions] = useState([]);
  const [Value, setValue] = useState('');
  const [ButtonState, setButtonState] = useState(false);
  const [Export, setExport] = useState('');
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#0c90e8");


  if (Options.length === 0) {
    let qry = axios

    qry.get(REPORT + '/ritalers', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }
    }).then((response) => {
      let Options = response.data.map(ritaler => ({ value: ritaler.value, label: ritaler.label }));
      console.log('data', response.data)
      console.log('options', Options)
      setOptions(Options)
    }).catch((error) => {
      console.error('getOptions', error)
      return new Error(error)
    })
  }


  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  return (

    <div style={{ width: 2000, margin: '1em' }}>
      <div className={classes.root}>

        <div style={{ width: '200px' }}>
          <Select source="ritaler"
            multi={false}
            options={Options}
            value={Options.find(obj => obj.value === Value)}
            onChange={e => setValue({ Options: e.value })} />
        </div>

        <Button color="primary" variant="contained" source="reportQuery" disabled={ButtonState} onClick={() => {
          setButtonState(true)
          setReport('Идет загрузка, подождите...')
          setLoading(!loading)

          let qry = axios

          var string = JSON.stringify(Value);
          let obj = JSON.parse(string);
          const data = {
            retailer: obj.Options,
            date_from: moment(Rep1_date_from).format('DD.MM.YYYY'),
            date_to: moment(Rep1_date_to).format('DD.MM.YYYY'),
            section: '1'
          }

          qry.post(REPORT + '/admin/data', data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem("token")
            }
          }).then((response) => {
            setLoading(loading)
            setButtonState(false)
            console.log('data', response.data)
            setReport(response.data)
            setExport(response.data)
          }).catch((error) => {
            setLoading(loading)
            setButtonState(false)
            return new Error(error)
          });
        }}>Запросить </Button>

        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} color="secondary" variant="contained" source="reportExport">экспорт</Button>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => {
            setReport('Идет загрузка, подождите...')
            setLoading(!loading)

            let qry = axios
            console.log('HTML to send', Export)
            qry.post(REPORT + '/admin/report1PDF', Export, {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token")
              }
            }).then((response) => {
              setLoading(loading)
              setReport(Export)
              let blob = new Blob([response.data], { type: "application/pdf" });
              saveAs(blob, "Report1.pdf");
            }).catch((error) => {
              setLoading(loading)
              setReport(Export)
              return new Error(error)
            });
          }}>PDF</MenuItem>

          <MenuItem onClick={() => {
            setReport('Идет загрузка, подождите...')
            setLoading(!loading)

            let qry = axios
            var string = JSON.stringify(Value);
            let obj = JSON.parse(string);

            const data = {
              retailer: obj.Options,
              date_from: moment(Rep1_date_from).format('DD.MM.YYYY'),
              date_to: moment(Rep1_date_to).format('DD.MM.YYYY'),
              section: '1'
            }

            qry.post(REPORT + '/admin/report1XLS', data, {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token")
              }
            }).then((response) => {
              setLoading(loading)
              setReport(Export)
              //console.log('data',response.data)
              //setReport(response.data)
              //var blob = new Blob([s2ab(atob(response.data))], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'});
              let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
              saveAs(blob, "Report1.xlsx");
            }).catch((error) => {
              setLoading(loading)
              setReport(Export)
              return new Error(error)
            });
          }}>EXCEL</MenuItem>
        </StyledMenu>
      </div>
      <div style={{ width: 1000, height: 1000 }} ref={ref}>
        <div dangerouslySetInnerHTML={{ __html: Report }} />
        <div style={{ width: 1000, height: 1000 }}>
        <ClipLoader color={color} loading={loading} css={override} size={100} />
        </div>
      </div>
    </div>
  );
};

export const ReportListManager1 = props => (
  <List title="Отчет распрстранителя" aside={<AsideRep1 />} {...props} syncWithLocation={false} bulkActionButtons={false} actions={false} pagination={false}>

    <SimpleForm toolbar={false} >
      <p>Наименование: {UserView} </p>
      <TextField source="title" label="Период отчета" />
      <DateInput label="Дата с" source="rep1_pub_at" defaultValue={Rep1_date_from} onChange={HandleChangeDateFromRep1} />
      <DateInput label="Дата по" source="rep1_pub_to" defaultValue={Rep1_date_to} onChange={handleChangeDateToRep1} />
    </SimpleForm>
  </List>
);



