
import jwt_decode from 'jwt-decode';
import {HOST} from './security/config'
//import {getHash} from './security/hash';


const authProvider ={
    login: ({ username, password }) => {
        //let user_password = getHash(password);
        //let user_password = password
        const request = new Request(HOST+'/authenticate', {
            method: 'POST',
            body: JSON.stringify({ username,  password}),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                console.log("response: "+response);
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                
                return response.json();
            })
            .then(({ token }) => {
                const decodedToken = jwt_decode(token);
                console.log(decodedToken)
                localStorage.setItem('token', token);
                localStorage.setItem('permissions', decodedToken.permissions);
                localStorage.setItem('user',decodedToken.username);
                localStorage.setItem('userview',decodedToken.userview);
                console.log("permissions: "+decodedToken.permissions);
                console.log("user: "+decodedToken.username)
                console.log("userview: "+decodedToken.userview)
            });
    },
    logout: () => {
        const request = new Request(HOST+'/logout', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' , 'Authorization' : localStorage.getItem("token")}),
        });
        fetch(request)
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('user');
        localStorage.removeItem('userview');
        return Promise.resolve();
    },
    checkError: error => {
      console.log(error);
    },
    checkAuth: () => {
        console.log("checkAuth token "+ localStorage.getItem('token'))
        console.log("permissions "+localStorage.getItem('permissions'));
        console.log("user "+localStorage.getItem('user'));
        console.log ("userview " +localStorage.getItem('userview'));
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        console.log("role "+role);
        return role ? Promise.resolve(role) : Promise.reject();
    }
}

export default authProvider;

