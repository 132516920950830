import React from 'react';
import { List, Datagrid, TextField, SimpleForm, TextInput, Edit, Create } from 'react-admin';

export const OutletList = props => (
  <List title="Точки продаж" {...props}>
   <Datagrid rowClick='edit'>
      <TextField source='id' label="№"/>
      <TextField source='name' label="Наименование"/>
      <TextField source='address'label="Адрес"/>
      <TextField source='phone' label="Телефон"/>
    </Datagrid>
  </List>
);

export const OutletEdit = props => (
  <Edit title="Точки продаж" {...props}>
    <SimpleForm>
      <TextField source='id' label="№"/>
      <TextInput source='name' label="Наименование"/>
      <TextInput source='address' label='Адрес'/>
      <TextInput source='phone' label="Телефон"/>
    </SimpleForm>
  </Edit>
);

export const OutletCreate = props => (
  <Create title="Точки продаж" {...props}>
    <SimpleForm>
      <TextInput source='name' label="Наименование"/>
      <TextInput source='address' label='Адрес'/>
      <TextInput source='phone' label="Телефон"/>
    </SimpleForm>
  </Create>
);