import React from "react";
import { SimpleForm, List } from 'react-admin';
import Button from "@material-ui/core/Button";
import Select from 'react-select';
import { useState } from 'react';
import axios from 'axios'
import { REPORT } from './security/config';
import {
  makeStyles
} from "@material-ui/core/styles";
import jwt_decode from 'jwt-decode';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClockLoader";



// const decodeJwt = require('jwt-decode');
/**form style code--------------------------------------*/
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));


/*---------------------------------------------------- */



var decodedToken;
var UserName;
var UserView;

try {
  decodedToken = jwt_decode(localStorage.getItem("token"))
  UserName = decodedToken.username;
  UserView = decodedToken.userview;
  console.log('r1 u ' + UserName);
  console.log('r1 v ' + UserView);
} catch {
  console.log('Error decode token');
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

const AsideRep1 = () => {
  const [Options, setOptions] = useState([]);
  const [Value, setValue] = useState('');
  const [Status, setStatus] = useState('');
  const [ButtonState, setButtonState] = useState(false);

  if (Options.length === 0) {
    let qry = axios

    qry.get(REPORT + '/feedbacktheme', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      }
    }).then((response) => {
      let Options = response.data.map(ritaler => ({ value: ritaler.value, label: ritaler.label }));
      console.log('data', response.data)
      console.log('options', Options)
      setOptions(Options)
    }).catch((error) => {
      console.error('getOptions', error)
      return new Error(error)
    })
  }
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#0c90e8");

  const classes = useStyles();


  return (
    <div style={{ width: 1400, margin: '1em' }}>
      <div className={classes.root}>
        <div>
          <label>Тема обращения</label>
        </div>
        <div style={{ width: '300px' }}>
          <Select source="ritaler"
            multi={false}
            options={Options}
            value={Options.find(obj => obj.value === Value)}
            onChange={e => setValue({ Options: e.value })} />
        </div>
        <div>
          <label>Сообщение </label>
        </div>
        <div style={{ width: 500, margin: '1em' }}>
          <textarea name="body"
            cols={100} rows={15} id="source_message"
          />
        </div>
        <Button color="primary" variant="contained" source="reportQuery" disabled={ButtonState} onClick={() => {
           setLoading(!loading)
           setStatus('Подождите, идет отправка сообщения...')
           setButtonState(true)
          let qry = axios
          //console.log("feedback", document.getElementById("source_message").value)
          var string = JSON.stringify(Value);
          let obj = JSON.parse(string);
         
          const data = {
            feedback_id: obj.Options,
            source_message: document.getElementById("source_message").value
          }

          qry.post(REPORT + '/feedback', data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem("token")
            }
            
          }
          ).then((response) => {
            setLoading(loading)
            setStatus('')
            setButtonState(false)
            console.log('data', response.data)
             var string_resp = JSON.stringify(response.data);
             let obj_resp = JSON.parse(string_resp);
             if (obj_resp.error_code === 0) {
            //if (response.status < 200 || response.status >= 300) {
              alert('Сообщение отправлено успешно!');
            } else {
              alert('Не удалось отправить сообщение, повторите попытку позднее. Код ошибки :'+obj_resp.error_code);
            }
          }).catch((error) => {
            setLoading(loading)
            setStatus('')
            setButtonState(false)
            return new Error(error)
          });
        }}>Отправить </Button>
      </div>
      <div style={{ width: 500, height: 300 }} >
        <div dangerouslySetInnerHTML={{ __html: Status }} />
        <div style={{ width: 300, height: 300 }}>
        <ClipLoader color={color} loading={loading} css={override} size={100} />
        </div>
      </div>
    </div>
    
  );
};

export const Feedback = props => (
  <List title="Обратная связь" aside={<AsideRep1 />} {...props} syncWithLocation={false} bulkActionButtons={false} actions={false} pagination={false}>

    <SimpleForm toolbar={false} visible={false}>
      <p>Наименование: {UserView} </p>
      <p>Ритейлер ID: {UserName} </p> 
    </SimpleForm>
  </List>
);



